<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Member Management')}} > {{ $t('Members') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Members') }}</h2>
        <el-button class="action-button" type="primary">{{ $t('Export Members') }}</el-button>
      </div>
      <ItemFilter :placeholder="$t('Search by member name, email')" default="memberName" :selection="true">
        <el-option :label="$t('Member Name Search')" value="memberName"></el-option>
        <el-option :label="$t('Email Search')" value="Email Search"></el-option>
      </ItemFilter>

      <div class="inventory-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Name') }}</th>
              <th scope="col">{{ $t('Email') }}</th>
              <th scope="col">{{ $t('Phone Number')}}</th>
              <th scope="col">{{ $t('Point Count')}}</th>
              <th scope="col">{{ $t('Order Count')}}</th>
              <th scope="col">{{ $t('Total Spend') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>歐陽龍</td>
              <td>kaka2020@gmail.com</td>
              <td>63095205</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>方星耀</td>
              <td>brody3166@gmail.com</td>
              <td>93286076</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>古彥霞</td>
              <td>cassie4636@icloud.com</td>
              <td>96136840</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>曹寶碧</td>
              <td>chloe1416@icloud.com</td>
              <td>98956047</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>張政聖</td>
              <td>sterling3357@gmail.com</td>
              <td>63072195</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>韓婉雲</td>
              <td>rodney7378@gmail.com</td>
              <td>953854297</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>陳宥齊</td>
              <td>christy584@gmail.com</td>
              <td>97020405</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>黃一瑗</td>
              <td>tarin1903@outlook.com</td>
              <td>92006730</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>王年鬆</td>
              <td>harper3099@icloud.com</td>
              <td>95385429</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>張凡瑛</td>
              <td>britton2467@gmail.com</td>
              <td>97020405</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>張利曼</td>
              <td>bledel2689@gmail.com</td>
              <td>98824995</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>蔡淇芬</td>
              <td>annie4468@outlook.com</td>
              <td>91817368</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>石少碩</td>
              <td>carson8048@gmail.com</td>
              <td>97161094</td>
              <td>200</td>
              <td>1</td>
              <td>200</td>
            </tr>
            <tr v-for="member in members" v-if="member.member_name">
              <td>{{ member.member_name }}</td>
              <td>{{ member.member_email }}</td>
              <td>{{ member.member_phone }}</td>
              <td>{{ member.order_count }}</td>
              <td>HK$ {{ member.total_spend }}</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'Member', params: {id: member.id}}">
                    {{ $t('Edit') }}
                  </router-link>
                  <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';
import Member from '@/lib/member';

export default {
  name: 'Memebers',
  components: {
    ItemFilter,
  },
  mounted(){
    //this.loadAllMembers();
  },
  methods:{
    async loadAllMembers(){
      try{
        const loginInfo = Common.getLoginInfo();
        const members = await Member.loadAllMembers(this.apiUrl, loginInfo);
        this.members = members;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      members: [],
      orignalMembers: [],
    }
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
